<template>
  <div class="reports-update">
    <div class="headline">
      <h1 class="title">
        Uredite zapisnik
      </h1>
      <div class="action">
        <Button class="btn-primary" @click.prevent="validateForm('componentForm')">
          <i class="el-icon-check"></i>
        </Button>
      </div>
    </div>
    <div class="form">
      <Form :model="formData" :rules="rules" ref="componentForm" @submit.native.prevent="validateForm('componentForm')">
        <div class="title">Općenito</div>
        <Row :gutter="16">
          <Col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <FormItem prop="item.content.id" label="Zapisnik štete br.">
              <Input
                  disabled
                  v-model="formData.item.content.id"
                  @change="setReportDraft"/>
            </FormItem>
          </Col>
          <Col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <FormItem prop="item.createdAt" label="Datum">
              <DatePicker
                  v-model="formData.item.createdAt"
                  type="date"
                  placeholder="Odaberite"
                  :picker-options="pickerOptions"
                  format="dd.MM.yyyy."
                  @change="setReportDraft">
              </DatePicker>
            </FormItem>
          </Col>
          <Col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <FormItem prop="clientId" label="Stranka">
              <Select
                  filterable
                  v-model="formData.clientId"
                  @change="setClient">
                <Option :label="`Novi klijent`" :value="'create'"></Option>
                <Option :label="`${item.content.contact.name}`" :value="item.id" v-for="item in clients" :key="item.id"></Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <div class="title">Podaci o vozilu</div>
        <Row :gutter="16">
          <Col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <FormItem prop="item.content.vehicle.type" label="Tip Vozila">
              <Input
                  v-model="formData.item.content.vehicle.type"
                  @change="setReportDraft"/>
            </FormItem>
          </Col>
          <Col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <FormItem prop="item.content.vehicle.registrationNumber" label="Registracija">
              <Input
                  v-model="formData.item.content.vehicle.registrationNumber"
                  @change="setReportDraft"/>
            </FormItem>
          </Col>
          <Col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <FormItem prop="item.content.vehicle.vin" label="Broj šasije">
              <Input
                  v-model="formData.item.content.vehicle.vin"
                  @change="setReportDraft"/>
            </FormItem>
          </Col>
          <Col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <FormItem prop="item.content.vehicle.color" label="Boja vozila">
              <Input
                  v-model="formData.item.content.vehicle.color"
                  @change="setReportDraft"/>
            </FormItem>
          </Col>
        </Row>
        <div class="title">Podaci o osiguranju</div>
        <Row :gutter="16">
          <Col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <FormItem prop="insuranceId" label="Osiguranje">
              <Select
                  :disabled="formData.item.content.damageList.length > 0"
                  v-model="formData.insuranceId"
                  @change="setInsurance">
                <Option :label="`${item.content.name}`" :value="item.id" v-for="item in insurances"
                        :key="item.id"></Option>
              </Select>
            </FormItem>
          </Col>
          <Col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <FormItem prop="item.content.damageReport" label="Izvid štete">
              <Input
                  v-model="formData.item.content.damageReport"
                  @change="setReportDraft"/>
            </FormItem>
          </Col>
        </Row>
        <div class="title">Šteta</div>
        <Row :gutter="16">
          <Col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <div class="damage-photo">
              <div class="photo" ref="photo">
                <img src="/photos/reports/vehicle.jpg" alt="" @click="createDamage">
              </div>
            </div>
          </Col>
        </Row>
        <div class="title">Dodatne informacije</div>
        <Row :gutter="16">
          <Col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
            <FormItem prop="item.content.additionalInformation.counterStatus" label="Stanje brojača">
              <InputNumber
                :min="0"
                controls-position="right"
                v-model="formData.item.content.additionalInformation.counterStatus"
                @change="setReportDraft"/>
            </FormItem>
          </Col>
          <Col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
            <FormItem prop="item.content.additionalInformation.counterStatus" label="Radni sati">
              <Input
                v-model="formData.item.content.additionalInformation.hoursWorked"
                @change="setReportDraft"/>
            </FormItem>
          </Col>
          <Col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <FormItem prop="item.content.additionalInformation.reporter" label="Procjenu obavio">
              <Input
                  v-model="formData.item.content.additionalInformation.reporter"
                  @change="setReportDraft"/>
            </FormItem>
          </Col>
        </Row>
        <div class="title">Obračun</div>
        <div class="calculation">
          <div class="item">
            <label>Hladno ravnanje lima:</label>
            <div class="content">
              <div class="total">
                {{ showAmountWithCurrency(formData.item.createdAt, formData.item.content.calculation.damageTotal) }}
              </div>
            </div>
          </div>
          <div class="item">
            <label>Montaža + Demontaža:</label>
            <div class="content">
              <FormItem>
                <InputNumber
                    class="el-input-number-sm"
                    :min="0"
                    precision="2"
                    controls-position="right"
                    v-model="formData.item.content.calculation.assemblyDisassembly"
                    @change="setReportDraft"/>
              </FormItem>
              <div v-if="!toggleSecondCurrency" class="value">
                €
              </div>
              <div v-else>
                HRK | {{ currencyConverter(formData.item.content.calculation.assemblyDisassembly) }} €
              </div>
            </div>
          </div>
          <div class="item">
            <label>Rabat:</label>
            <div class="content">
              <FormItem>
                <InputNumber
                  class="el-input-number-sm"
                  :min="0"
                  precision="2"
                  controls-position="right"
                  v-model="formData.item.content.calculation.discount"
                  @change="setReportDraft"/>
              </FormItem>
              <div class="value">
                %
              </div>
            </div>
          </div>
          <div class="item">
            <label>Lakiranje:</label>
            <div class="content">
              <FormItem>
                <InputNumber
                    class="el-input-number-sm"
                    :min="0"
                    precision="2"
                    controls-position="right"
                    v-model="formData.item.content.calculation.lacquering"
                    @change="setReportDraft"/>
              </FormItem>
              <div v-if="!toggleSecondCurrency" class="value">
                €
              </div>
              <div v-else>
                HRK | {{ currencyConverter(formData.item.content.calculation.lacquering) }} €
              </div>
            </div>
          </div>
          <div class="item">
            <label>Dijelovi:</label>
            <div class="content">
              <FormItem>
                <InputNumber
                    class="el-input-number-sm"
                    :min="0"
                    precision="2"
                    controls-position="right"
                    v-model="formData.item.content.calculation.parts"
                    @change="setReportDraft"/>
              </FormItem>
              <div v-if="!toggleSecondCurrency" class="value">
                €
              </div>
              <div v-else>
                HRK | {{ currencyConverter(formData.item.content.calculation.parts) }} €
              </div>
            </div>
          </div>
          <div class="item">
            <label>Ukupno bez PDV-a:</label>
            <div class="content">
              <div class="total">
                {{ formData.item.content.calculation.totalWithoutVAT.toFixed(2) }}
              </div>
              <div v-if="!toggleSecondCurrency" class="value">
                €
              </div>
              <div v-else>
                HRK | {{ currencyConverter(formData.item.content.calculation.totalWithoutVAT.toFixed(2)) }} €
              </div>
            </div>
          </div>
          <div class="item">
            <label>PDV 25%:</label>
            <div class="content">
              <div class="total">
                {{ formData.item.content.calculation.vat.toFixed(2) }}
              </div>
              <div v-if="!toggleSecondCurrency" class="value">
                €
              </div>
              <div v-else>
                HRK | {{ currencyConverter(formData.item.content.calculation.vat.toFixed(2)) }} €
              </div>
            </div>
          </div>
          <div class="item">
            <label>Ukupno s PDV-om:</label>
            <div class="content">
              <div class="total">
                {{ formData.item.content.calculation.totalWithVAT.toFixed(2) }}
              </div>
              <div v-if="!toggleSecondCurrency" class="value">
                €
              </div>
              <div v-else>
                HRK | {{ currencyConverter(formData.item.content.calculation.totalWithVAT.toFixed(2)) }} €
              </div>
            </div>
          </div>
        </div>
        <div class="title">Napomene</div>
        <Row :gutter="16">
          <Col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <FormItem prop="item.content.notes.note" label="Napomena">
              <Input
                  type="textarea"
                  :rows="4"
                  v-model="formData.item.content.notes.note"
                  @change="setReportDraft"/>
            </FormItem>
            <FormItem prop="item.content.notes.internalNote" label="Interna napomena">
              <Input
                  type="textarea"
                  :rows="4"
                  v-model="formData.item.content.notes.internalNote"
                  @change="setReportDraft"/>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </div>

    <AdministrationReportsUpdateModalsDamageCreate
        :isDamageCreateDialogVisible="isDamageCreateDialogVisible"
        :coordinates="coordinates"
        :insurances="insurances"
        @setDamage="setDamage"
        @closeDialog="isDamageCreateDialogVisible = false"/>
    <AdministrationReportsUpdateModalsClientCreate
        :isClientCreateDialogVisible="isClientCreateDialogVisible"
        @getClients="getClients"
        @closeDialog="isClientCreateDialogVisible = false"/>
  </div>
</template>

<script>
// Mixins
import {Common} from '@/mixins/Common';

// Components
import AdministrationReportsUpdateModalsDamageCreate from '@/views/Administration/Reports/Update/Modals/Damage/Create';
import AdministrationReportsUpdateModalsClientCreate from '@/views/Administration/Reports/Update/Modals/Client/Create';

// Element UI
import {
  Row,
  Col,
  Form,
  FormItem,
  Input,
  InputNumber,
  DatePicker,
  Select,
  Option,
  Button,
  Notification
} from 'element-ui';
import lang from 'element-ui/lib/locale/lang/hr';
import locale from 'element-ui/lib/locale';

locale.use(lang);

// Logic
export default {
  name: 'AdministrationReportsUpdateIndex',
  mixins: [Common],
  components: {
    AdministrationReportsUpdateModalsDamageCreate,
    AdministrationReportsUpdateModalsClientCreate,
    Row,
    Col,
    Form,
    FormItem,
    Input,
    InputNumber,
    DatePicker,
    Select,
    Option,
    Button,
  },
  data() {
    return {
      isClientCreateDialogVisible: false,
      isDamageCreateDialogVisible: false,
      coordinates: {
        x: 0,
        y: 0,
      },
      limitDate: '2024-02-16',
      formData: {
        clientId: null,
        insuranceId: null,
        markId: null,
        item: {
          content: {
            id: '',
            client: {
              id: '',
              content: {
                company: {
                  name: '',
                  address: '',
                  vatNumber: ''
                },
                contact: {
                  name: '',
                  phone: '',
                  mobilePhone: '',
                  email: '',
                }
              }
            },
            vehicle: {
              type: '',
              registrationNumber: '',
              vin: '',
              color: ''
            },
            insurance: {
              id: '',
              content: {
                name: '',
                list: []
              }
            },
            damageReport: '',
            damageList: [],
            additionalInformation: {
              counterStatus: 0,
              hoursWorked: 0,
              reporter: ''
            },
            calculation: {
              damageTotal: 0,
              assemblyDisassembly: 0,
              lacquering: 0,
              parts: 0,
              discount: 0,
              totalWithoutVAT: 0,
              vat: 0,
              totalWithVAT: 0
            },
            notes: {
              note: '',
              internalNote: ''
            }
          },
          createdAt: new Date()
        }
      },
      rules: {
        clientId: [
          {
            required: true,
            message: 'Molimo ispunite polje',
            trigger: ['blur']
          }
        ],
        insuranceId: [
          {
            required: true,
            message: 'Molimo ispunite polje',
            trigger: ['blur']
          }
        ],
        markId: [
          {
            required: true,
            message: 'Molimo ispunite polje',
            trigger: ['blur']
          }
        ],
        item: {
          content: {
            id: [
              {
                required: true,
                message: 'Molimo ispunite polje',
                trigger: ['blur']
              }
            ],
          },
          createdAt: [
            {
              required: true,
              message: 'Molimo ispunite polje',
              trigger: ['blur']
            }
          ]
        }
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      clients: [],
      insurances: [],
    }
  },
  watch: {
    'formData.item.content.calculation.damageTotal'() {
      this.setCalculation();
    },
    'formData.item.content.calculation.assemblyDisassembly'() {
      this.setCalculation();
    },
    'formData.item.content.calculation.discount'() {
      this.setCalculation();
    },
    'formData.item.content.calculation.lacquering'() {
      this.setCalculation();
    },
    'formData.item.content.calculation.parts'() {
      this.setCalculation();
    },
  },
  computed: {
    toggleSecondCurrency() {
      if (this.formData?.item?.createdAt)
        return this.formData.item.createdAt.toString().slice(0,10) <= this.limitDate;
    }
  },
  methods: {
    validateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitForm(formName);
        } else {
          return false;
        }
      });
    },
    currencyConverter(price) {
      return (price/7.53450).toFixed(2)
    },
    submitForm(formName) {
      this.setLoadingComponent(true);
      this.Axios.post(`administration/report/${this.$route.params.id}/update`, {
        content: JSON.stringify(this.formData.item.content),
        created_at: this.formData.item.createdAt
      }).then(response => {
        this.message = response.data.content.message;
        this.status = response.data.content.status;
      }).catch(error => {
        if (error.response) {
          this.message = error.response.data.content.message;
          this.status = error.response.data.content.status;
        }
      }).finally(() => {
        this.setLoadingComponent(false);
        this.setNotification();
        this.setData(formName);
        this.$store.dispatch('setReportDraft', null);
        this.$router.push({name: 'AdministrationReportsRootIndex'});
      });
    },
    setData(formName) {
      this.$refs[formName].resetFields();
    },
    createDamage(e) {
      if (this.formData.item.content.insurance.id !== "") {
        const rect = e.target.getBoundingClientRect();
        this.coordinates = {
          x: e.clientX - rect.left,
          y: e.clientY - rect.top
        };
        this.isDamageCreateDialogVisible = true;
      } else {
        Notification({
          title: "",
          message: "Molimo odaberite osiguranje",
          type: "warning",
          offset: 80
        });
      }
    },
    removeDamage(data) {
      this.formData.item.content.damageList = this.formData.item.content.damageList.filter((item, index) => {
        return index !== data;
      });
      this.setDamageMarker();
      this.setReportDraft();
      this.setDamageTotal();
    },
    setDamage(data) {
      this.formData.item.content.damageList.push(data);
      this.setDamageMarker();
      this.setReportDraft();
      this.setDamageTotal();
    },
    setDamageMarker() {
      document.querySelectorAll(".marker").forEach(e => e.remove());
      this.formData.item.content.damageList.forEach((item, index) => {
        const marker = document.createElement('div');
        const type = item.type === 'parking' ? 'P' : item.quantity;
        const innerHtmlDoubleCurrency = `<div>${type} | <strong>${item.cost.toFixed(2)} HRK | ${this.currencyConverter(item.cost)} EUR</strong><div class="remove" id="remove${index}">Ukloni</div></div>`;
        const innerHtmlEuroOnly = `<div>${type} | <strong>${item.cost.toFixed(2)} € </strong><div class="remove" id="remove${index}">Ukloni</div></div>`;
        const innerHtmlContent = this.toggleSecondCurrency ? innerHtmlDoubleCurrency : innerHtmlEuroOnly;
        marker.classList.add('marker');
        marker.style.top = `${item.y}px`;
        marker.style.left = `${item.x}px`;
        marker.innerHTML = innerHtmlContent
        this.$refs.photo.appendChild(marker);

        document.querySelector(`#remove${index}`).addEventListener('click', event => {
          this.removeDamage(index);
        })
      });
    },
    setDamageTotal() {
      let total = 0;
      this.formData.item.content.damageList.forEach(item => {
        total += parseFloat(item.cost);
      });

      this.formData.item.content.calculation.damageTotal = total;
      this.setReportDraft();
    },
    setClient() {
      if (this.formData.clientId === 'create') {
        this.formData.clientId = null;
        this.formData.item.content.client = {
          id: '',
          content: {
            company: {
              name: '',
              address: '',
              vatNumber: ''
            },
            contact: {
              name: '',
              phone: '',
              mobilePhone: '',
              email: '',
            }
          }
        };
        this.isClientCreateDialogVisible = true;
      } else {
        this.formData.item.content.client = this.clients.find(item => item.id === this.formData.clientId);
        this.setReportDraft();
      }
    },
    setInsurance() {
      this.formData.item.content.insurance = this.insurances.find(item => item.id === this.formData.insuranceId);
      this.setReportDraft();
    },
    setReportDraft() {
      this.$store.dispatch('setReportDraft', this.formData)
    },
    setCalculation() {
      if (this.formData.item.content.calculation.assemblyDisassembly === undefined) {
        this.formData.item.content.calculation.assemblyDisassembly = 0;
      }

      if (this.formData.item.content.calculation.discount === undefined) {
        this.formData.item.content.calculation.discount = 0;
      }

      if (this.formData.item.content.calculation.lacquering === undefined) {
        this.formData.item.content.calculation.lacquering = 0;
      }

      if (this.formData.item.content.calculation.parts === undefined) {
        this.formData.item.content.calculation.parts = 0;
      }

      let total = this.formData.item.content.calculation.damageTotal + this.formData.item.content.calculation.assemblyDisassembly;
      let totalWithDiscount = total - (total * (this.formData.item.content.calculation.discount / 100));
      let totalWithAdditionalServices = totalWithDiscount + this.formData.item.content.calculation.lacquering + this.formData.item.content.calculation.parts;
      let totalWithoutVAT = totalWithAdditionalServices;
      let vat = totalWithoutVAT * 0.25;
      let totalWithVAT = totalWithoutVAT + vat;

      this.formData.item.content.calculation.totalWithoutVAT = totalWithoutVAT;
      this.formData.item.content.calculation.vat = vat;
      this.formData.item.content.calculation.totalWithVAT = totalWithVAT;
    },
    setFormDataFromReportDraft() {
      if (this.$store.state.reportDraft) {
        this.formData = this.$store.state.reportDraft;

        if (this.$store.state.reportDraft.item.content.client.id !== "" && this.$store.state.reportDraft.item.content.client.id !== "create") {
          this.formData.clientId = this.$store.state.reportDraft.item.content.client.id
        }

        if (this.$store.state.reportDraft.item.content.insurance.id !== "") {
          this.formData.insuranceId = this.$store.state.reportDraft.item.content.insurance.id
        }
      }
    },
    getClients() {
      this.setLoadingComponent(true);
      this.Axios.get('administration/client').then(response => {
        if (response.data.content.data) {
          this.clients = response.data.content.data;
        } else {
          this.clients = [];
        }
      }).catch(error => {
        if (error.response) {
          this.message = error.response.data.content.message;
          this.status = error.response.data.content.status;
          this.clients = [];
        }
      }).finally(() => {
        this.setLoadingComponent(false);
      });
    },
    getInsurances() {
      this.setLoadingComponent(true);
      this.Axios.get('administration/insurance').then(response => {
        if (response.data.content.data) {
          this.insurances = response.data.content.data;
          if (this.$store.state.reportDraft && this.$store.state.reportDraft.item.content.insurance.id !== "") {
            this.insurances.forEach(insurance => {
              if (insurance.id === this.$store.state.reportDraft.item.content.insurance.id) {
                this.$store.state.reportDraft.item.content.insurance = insurance;
              }
            });
          }
        } else {
          this.insurances = [];
        }
      }).catch(error => {
        if (error.response) {
          this.message = error.response.data.content.message;
          this.status = error.response.data.content.status;
          this.insurances = [];
        }
      }).finally(() => {
        this.setLoadingComponent(false);
      });
    },
    getOperatingItem() {
      this.setLoadingComponent(true);
      this.Axios.get(`administration/report/${this.$route.params.id}/get`).then(response => {
        if (response.data.content.data) {
          this.$store.dispatch('setReportDraft', {
            clientId: null,
            insuranceId: null,
            markId: null,
            item: {
              content: response.data.content.data.content,
              createdAt: response.data.content.data.created_at
            }
          });
          this.setFormDataFromReportDraft();
        }
      }).catch(error => {
        if (error.response) {
          this.message = error.response.data.content.message;
          this.status = error.response.data.content.status;
          this.operatingItem = {
            id: '',
            content: {
              name: '',
              list: []
            }
          };
        }
      }).finally(() => {
        this.setDamageMarker();
        this.setLoadingComponent(false);
      });
    },
    getType(data) {
      return data === 'hail' ? "Tuča" : "Parking";
    },
    showAmountWithCurrency(date, amount) {
      if(!date)
        return;
      const dateSliced = date.toString().slice(0,10);
      if (dateSliced <= this.limitDate)
        return `${amount.toFixed(2)} HRK | ${this.currencyConverter(amount)} €`;
      return `${amount.toFixed(2)} €`;
    },
  },
  created() {
    this.initialize();
    this.getClients();
    this.getInsurances();
    this.getOperatingItem();
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/core/_variables.scss';

.reports-update {
  .form {
    .photo {
      .marker {
        position: absolute;
        width: auto;
        height: auto;
        background-color: $grey-600;
        font-size: 12px;
        color: white;
        text-align: center;
        padding: 8px;
        border-radius: 4px 4px 0 0;

        div {
          + div {
            margin-top: 4px;
          }
        }

        .remove {
          position: absolute;
          bottom: -20px;
          width: 100%;
          left: 0;
          padding: 4px 0;
          background: $grey-400;
          border-radius: 0 0 4px 4px;
          font-size: 12px;
          font-weight: 700;
          text-transform: uppercase;
          cursor: pointer;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/scss/core/_variables.scss';
@import '@/assets/scss/mixins/_functions.scss';
@import '@/assets/scss/mixins/_breakpoints.scss';
@import '@/assets/scss/elements/_buttons.scss';

.reports-update {
  .form {
    margin-top: 24px;
    margin-bottom: 48px;

    .title {
      margin-top: 24px;
      margin-bottom: 16px;
      font-weight: 700;
    }

    .damage-photo {
      .photo {
        width: 702px;
        border: 1px solid $grey-200;
        border-radius: 12px;
        overflow-x: hidden;
        padding: 16px;
        margin-bottom: 16px;
      }
    }

    .calculation {
      border: 1px solid $grey-200;
      border-radius: 12px;
      padding: 16px;
      margin-bottom: 44px;

      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }

        .content {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: auto;
          height: 24px;
          text-align: right;

          .total {
            + .value {
              position: relative;
              left: 3px;
              margin-left: 1px;
            }
          }

          .value {
            width: auto;
            margin-left: 4px;
            text-align: left;
          }

          .el-form-item {
            margin-top: 2px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
