<template>
  <Dialog title="Novi zapis" :visible="isDamageCreateDialogVisible" :show-close="false">
    <div class="form">
      <Form :model="formData" :rules="rules" ref="componentForm" @submit.native.prevent="validateForm('componentForm')">
        <FormItem prop="type" label="Tip">
          <Select v-model="formData.type">
            <Option :label="'Tuča'" :value="'hail'"></Option>
            <Option :label="'Parking'" :value="'parking'"></Option>
          </Select>
        </FormItem>
        <FormItem prop="quantity" :label="'Broj udubina'" v-if="isHail">
          <InputNumber
              :min="0"
              controls-position="right"
              v-model="formData.quantity"/>
        </FormItem>
        <FormItem prop="list" :label="'Kategorija'" v-if="!isHail">
          <Select v-model="formData.list">
            <Option :label="item.name" :value="index" v-for="(item, index) in parkingList" :key="index"></Option>
          </Select>
        </FormItem>
        <FormItem prop="radius" label="Promjer udubina" :class="{ 'd-none': !isHail }">
          <Select v-model="formData.radius">
            <Option :label="'do 20mm'" :value="'costRadiusTo20'"></Option>
            <Option :label="'od 20 do 35mm'" :value="'costRadiusFrom20To35'"></Option>
            <Option :label="'od 35 do 50mm'" :value="'costRadiusFrom35To50'"></Option>
          </Select>
        </FormItem>
        <FormItem prop="cost" label="Cijena">
          <InputNumber
              :min="0"
              precision="2"
              controls-position="right"
              v-model="formData.cost"/>
        </FormItem>
        <FormItem>
          <Button
            class="btn-grey"
            icon="el-icon-close"
            @click="$emit('closeDialog')"/>
          <Button
            class="btn-light"
            icon="el-icon-check"
            :loading="loading"
            @click="validateForm('componentForm')" />
        </FormItem>
      </Form>
    </div>
  </Dialog>
</template>

<script>
// Mixins
import {Common} from '@/mixins/Common';

// Element UI
import {Form, FormItem, InputNumber, Select, Option, Button, Dialog} from 'element-ui';
import lang from 'element-ui/lib/locale/lang/hr';
import locale from 'element-ui/lib/locale';

locale.use(lang);

// Logic
export default {
  name: 'AdministrationReportsCreateModalsDamageCreate',
  mixins: [Common],
  components: {
    Form,
    FormItem,
    InputNumber,
    Select,
    Option,
    Button,
    Dialog
  },
  props: [
    'isDamageCreateDialogVisible',
    'coordinates',
    'insurances',
  ],
  data() {
    return {
      formData: {
        type: 'hail',
        list: null,
        name: null,
        quantity: 0,
        radius: null,
        cost: 0,
        x: 0,
        y: 0
      },
      rules: {
        type: [
          {
            required: true,
            message: 'Molimo ispunite polje',
            trigger: ['blur']
          }
        ],
        quantity: [
          {
            required: true,
            message: 'Molimo ispunite polje',
            trigger: ['blur']
          }
        ],
        radius: [
          {
            required: true,
            message: 'Molimo ispunite polje',
            trigger: ['blur']
          }
        ],
        cost: [
          {
            required: true,
            message: 'Molimo ispunite polje',
            trigger: ['blur']
          }
        ]
      }
    }
  },
  computed: {
    isHail() {
      return this.formData.type === 'hail';
    },
    hailList() {
      if (this.$store.state.reportDraft) {
        return this.$store.state.reportDraft.item.content.insurance.content.list.filter(item => item.type === 'hail');
      }
    },
    parkingList() {
      if (this.$store.state.reportDraft) {
        return  this.$store.state.reportDraft.item.content.insurance.content.list.filter(item => item.type === 'parking');
      }
    }
  },
  methods: {
    validateForm(formName) {
      if (this.isHail) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.submitForm(formName);
          } else {
            return false;
          }
        });
      } else {
        if (this.formData.list) {
          this.submitForm(formName);
        }
      }
    },
    submitForm(formName) {
      this.$emit('setDamage', {
        catalog: this.formData.catalog,
        type: this.formData.type,
        list: this.formData.list,
        name: this.formData.name,
        quantity: this.formData.quantity,
        radius: this.formData.radius,
        cost: this.formData.cost,
        x: this.coordinates.x,
        y: this.coordinates.y
      });
      this.$emit('closeDialog');
      this.setData(formName);
    },
    setData(formName) {
      this.$refs[formName].resetFields();
    }
  },
  watch: {
    'formData.type'() {
      this.operatingItem = null;
      this.formData.list = null;
      this.formData.quantity = 0;
      this.formData.radius = null;
      this.formData.cost = 0;
    },
    'formData.list'() {
      this.operatingItem = null;
      this.formData.quantity = 0;
      this.formData.radius = null;
      this.formData.cost = 0;

      if (!this.isHail && this.formData.list !== null) {
        this.formData.name = this.parkingList[this.formData.list].name;
        this.formData.cost = this.parkingList[this.formData.list].cost;
      }
    },
    'formData.quantity'() {
      this.operatingItem = null;
      this.formData.radius = null;
      this.formData.cost = 0;

      if (this.isHail) {
        this.hailList.forEach(item => {
          if ((this.formData.quantity >= item.quantityFrom && this.formData.quantity <= item.quantityTo)) {
            this.operatingItem = item;
          }
        });
      }
    },
    'formData.radius'() {
      if (this.isHail && this.operatingItem !== null && this.formData.radius !== null) {
        this.formData.cost = this.operatingItem[this.formData.radius];
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/core/_variables.scss';
@import '@/assets/scss/mixins/_functions.scss';
@import '@/assets/scss/mixins/_breakpoints.scss';
@import '@/assets/scss/elements/_buttons.scss';
</style>
